.cart-btn-bar {
    float: right;
}

.m-card-title {
    display: inline-block;
}

.btn-cancel {
    margin: 20px;
    background-color: #ddd;
    border: none;
    padding-right: 25px;
    padding-left: 25px;
}

.m-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.card-footer {
    text-align: right;
}

.btn-delete {
    margin-left: 30px;
    padding: 5px 30px;
    background-color: #999;
    border: none;
    font-weight: 600;
    color: #fff;
}

.m-btn-danger {
    font-weight: 600;
    border: none;
    padding: 5px 25px;
}

.wrapper:before {
    background: linear-gradient(90deg, #009ee0 30%, #c6000000 100%) !important;
}

.sidebar-brand,
.sidebar-brand:hover {
    /* background: linear-gradient(90deg, red 0%, #C60000 100%) !important; */
    background-color: #009ee0;
}

.logo-knauf {
    padding: 0;
    margin: 0;
    font-weight: 600;
    text-align: center;
    font-size: 22px;
}

.create-post-select-img-container {
    text-align: center;
    border: 1px solid #ced4da;
    padding: 20px 0;
    cursor: pointer;
    height: 250px;
}

.create-post-select-img-container i {
    font-size: 50px;
    position: absolute;
    z-index: 0;
    top: calc(50% -50px);
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    font-weight: 600;
}

.post-cover-posts {
    border-radius: 0;
    width: 100px;
    height: 55px;
}

.previews-img-container {
    display: flex;
    flex-flow: wrap;
}

.previews-img-container .item {
    display: inline-flex;
    width: 25%;
    cursor: pointer;
    margin: 10px;
    border: 1px solid;
    padding: 10px;
}

.previews-img-container .item img {
    display: block;
    overflow: hidden;
}

.btn-x-card {
    position: absolute;
    top: -8px;
    right: -8px;
    font-weight: 600;
    background-color: #ccc;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

.avatar-gift {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.greeting-friend-page .card-title {
    display: inline-block;
    padding: 0;
    margin: 0;
    padding-top: 6px;
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    width: 150px;
}

.box-shadow-none {
    box-shadow: none
}

.m-btn-save2 {
    background-color: #28a745;
    margin-left: 40px;
    min-width: 120px;
    border-color: #28a745;
}

.tag-role-user {
    font-weight: 600;
    padding: 1px 6px;
    color: #fff;
    border-radius: 2px !important;
    font-size: 13px;
    margin-right: 5px;
}

.text-muted {
    font-weight: 600;
    color: #333 !important;
    font-size: 20px;
}

.account-container-widget .banner {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.account-container-widget .banner-store {
    aspect-ratio: 19/6;
    width: 70%;
    object-fit: cover;
    object-position: left;
}


.invoice-account .form-group {
    margin-bottom: 5px;
}

.invoice-account .form-group label {
    display: inline-block;
    width: 100px;
}

.invoice-account .form-group input {
    display: inline-block;
    margin-left: 1px;
    border: none;
    font-weight: 500;
    width: inherit;
    color: black;
    width: calc(100% - 110px);
}

.invoice-account .form-control.ant-select {
    border: none;
    padding: 0px;
    width: calc(100% - 110px);
    font-weight: 600;
    color: #333;
    display: inline-block;
}

.invoice-account .form-control.ant-select {
    border: none;
    padding: 0px;
    width: calc(100% - 110px);
    font-weight: 600;
    color: #333;
}

.invoice-account .form-control.ant-select .ant-select-selector {
    border: none;
}

.invoice-account .form-control.ant-select .ant-select-selection-item {
    font-weight: 500;
}

.invoice-table {
    color: #333;
}
.invoice-table input {
    border: none;
    font-weight: 500;
}

.invoice-table table tr {
    border: 1px dotted #dee2e6;
}

.invoice-table.table thead th {
    border: none;
}

.invoice-table.table td, .invoice-table.table th {
    padding-left: 0 !important;
}

.invoice textarea {
    border: 1px dashed rgb(191, 191, 191);
    height: 114px;
}

.invoice .invoice-status {
    padding: 2px 10px;
    color: #fff;
    font-weight: 600;
    border-radius: 3px !important;
    margin-left: 7px;
}

.invoice .link-img {
    font-weight: 600;
    color: #3b7ddd;
    cursor: pointer;
}


.invoice .form-control:disabled, .form-control[readonly] {
    background: none !important;
}


.invoice .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: black;
    background: none;
}

.sm-table th {
    padding: 2px;
}

.sm-table td {
    padding: 2px;
    text-align: left;
}

.inline-block {
    display: inline-block;
}