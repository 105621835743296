@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-btn {
  border-radius: 4px !important;
}

.btn-green {
  background-color: #3b7ddd;
  color: #fff;
  min-width: 100px;
}

.input-detail-status {
  color: #fff;
  font-weight: 600;
}

.cart-paging {
  text-align: center;
  padding: 30px;
}

.nav-tabs .nav-link {
  border-radius: 5px;
  padding: 7px 30px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #3d9fe1;
  border-color: #dee2e6 #dee2e6 #f4f7fc;
}

.nav-tabs {
  border: none;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red;
  padding: 5px;
}
/* border-top-right-radius: 0;
    border-top-left-radius: 0; */

tr.border-none th {
  border: none;
}

.ant-avatar-circle {
  border-radius: 50% !important;
}

.anticon {
  vertical-align: baseline !important;
}

.btn-green {
  background-color: #00b96b;
  border-color: #00b96b;
  color: #fff;
}