.popup {
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &__wrapper {
      margin: 24px;
      &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 8px;
      }
  
      &__desc {
        text-align: center;
        font-size: 15px;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 65vw;
      }
    }
  
    &__group-btn {
      height: 48px;
      width: 100%;
  
      display: flex;
      align-items: center;
  
      border-top: 0.5px solid #3c3c43;
      &__item {
        flex: 1;
  
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
      }
  
      .left {
        font-size: 16px;
        line-height: 22px;
        color: #1194ff;
      }
  
      .right {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #dc1f18;
        border-left: 0.5px solid #3c3c43;
      }
    }
  }
  
  
  .add-more-lquiz {
    text-align: center;
      padding: 5px;
      font-weight: 600;
      cursor: pointer;
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }

  .react-responsive-modal-modal {
    max-width: none !important;
  }